<template>

<div class="quantity" v-click-outside="onOutsideClick" >

	<input type="text" ref="text" class="quantity-value" :value="value" v-on:keyup="onKeyUp" v-on:mouseleave="onValueLeave" v-on:click="onValueClick" v-on:keydown="onKeyDown" v-on:mouseover="onValueOver" />

	<div class="quantity-slider-wrapper" v-show="is.expanded" v-on:mouseover="onValueOver" v-on:mouseleave="onValueLeave">

		<div ref="input" class="quantity-slider"></div>

	</div>

</div>
	
</template>

<script>

import store from '../store'
import vClickOutside from 'v-click-outside'
import noUiSlider from 'nouislider'
import _ from 'underscore'

export default {

	directives:{clickOutside:vClickOutside.directive},
	
	data:function(){return{slider:!1,currentValue:0,timer:false,is:{expanded:!1}}},
	
	computed:{value:function(){return store.getters["selected/quantity"]}},
	
	mounted:function(){
		
		noUiSlider.create(this.$refs.input,{input:this.input,step:1,start:2,range:{min:1,max:100},margin:0,connect:!0,behaviour:"tap-drag"})
		
		this.slider=this.$refs.input.noUiSlider

		this.slider.on("update",function(t){
			store.commit("selected/quantity",Math.round(t[0]))
		})
			
		this.slider.on("change",function(t){
			store.commit("selected/quantity",Math.round(t[0]))
		})

	},
	
	methods:{
		
		onKeyUp:function(){
			store.commit("selected/quantity",this.$refs.text.value)
			this.slider.set(this.$refs.text.value)
		},

		onKeyDown:function(t){
			
			var e=t.charCode||t.keyCode||0;8===e||9===e||13===e||46===e||110===e||190===e||e>=35&&e<=40||e>=48&&e<=57||e>=96&&e<=105||t.preventDefault()

		},
		
		onValueClick:function(){this.is.expanded=!0},
		
		onValueOver:function(){
			clearTimeout(this.timer)
			this.is.expanded=!0
		},
		
		onValueLeave:function(){

			this.timer = _.delay(function() {
				this.is.expanded = false
			}.bind(this), 500);

		},
		
		onOutsideClick:function(){this.is.expanded=!1}
		
	}
	
}
</script>

<style scoped>

.quantity{padding:10px 0 9px}

@media only screen and (max-width:1199px){
	
	.quantity{padding:1px 0 9px}}
	
	.quantity-value{height:68px;width:180px;border:2px solid #d7d7d7;color:#284c8f;font-family:Lato,sans-serif;line-height:64px;text-align:right;padding:0 10px;font-size:60px;font-weight:700;cursor:pointer

}
	
@media only screen and (max-width:1199px){
	
	.quantity-value{height:40px;width:94px;border:1px solid #d7d7d7;line-height:38px;padding:0 10px;font-size:32px;font-weight:700}
	
}
	
.quantity-slider-wrapper{position:absolute;background-color:#fff;border:1px solid hsla(0,0%,72%,.5);border-radius:10px;box-shadow:0 25px 41.61px 15.39px rgba(0,0,0,.18);width:420px;top:90px;padding:28px 24px;left:-20px;z-index:10}

@media only screen and (min-width:640px) and (max-width:1199px){
	
	.quantity-slider-wrapper{width:230px;top:50px;padding:20px 12px;left:-10px}
	
}

@media only screen and (min-width:480px) and (max-width:639px){
	
	.quantity-slider-wrapper{width:440px;top:50px;padding:20px 12px;left:-10px}
	
}

@media only screen and (max-width:479px){
	
	.quantity-slider-wrapper{width:100%;top:50px;padding:20px 12px;left:0}
	
}

.quantity-slider{width:100%;position:relative;height:8px}

</style>