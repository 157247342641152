import vuex from 'vuex'
import vue from 'vue'

vue.use(vuex)

export default new vuex.Store({

	state:{
		data: {
			assumptions:{
				materials:{
					"aluminum-can":{name:"Aluminum cans",weight:.02866,saving:-206.949619523587},
					box:{name:"Cereal boxes",weight:.15,saving:.492091402213021},
					"detergent-bottle":{name:"Detergent bottles",weight:.33,saving:-51.431427964035},
					"glass-bottle":{name:"Glass bottles",weight:1.08,saving:-2.65280981969929},
					magazine:{name:"Magazines",weight:.3,saving:-1.08711086042437},
					"tin-can":{name:"Steel/tin cans",weight:.13,saving:-20.4936010476923}
				},
				appliances:{
					bulb:{name:"Light bulb",kwh:.014},
					"hair-dryer":{name:"Hair dryer",kwh:1.5375},
					laptop:{name:"Laptop",kwh:.05},
					tv:{name:"Television",kwh:.12},
					"washing-machine":{name:"Clothes washer",kwh:.5}
				}
			},
			options:{               
				materials:["aluminum-can","box","detergent-bottle","glass-bottle","magazine","tin-can"],
				appliances:["bulb","hair-dryer","laptop","tv","washing-machine"]
			},   
			conversions:{tons:2e3,kwh:10.235925}
		},
		selected:{material:"aluminum-can",appliance:"bulb",quantity:2}
	},

	getters:{

		selected:function(t){return t.selected},
		
		"selected/material":function(t){return t.data.assumptions.materials[t.selected.material]},
		
		"selected/appliance":function(t){return t.data.assumptions.appliances[t.selected.appliance]},
		
		"selected/quantity":function(t){return t.selected.quantity},
		
		"options/appliances":function(t){return t.data.options.appliances},
		
		"options/materials":function(t){return t.data.options.materials},
		
		appliances:function(t){return t.data.assumptions.appliances},
		
		materials:function(t){return t.data.assumptions.materials},
		
		hours:function(t,e){
			
			var n=e["selected/appliance"]
			var i=e["selected/material"]
			
			var s=i.weight*t.selected.quantity/t.data.conversions.tons
			var a=parseFloat((1e3*Math.abs(s*i.saving)).toFixed(2))
			var o=parseFloat((a/t.data.conversions.kwh).toFixed(2))

			var hours = parseFloat(o/n.kwh)
			
			return (hours < 0.1) ? hours.toFixed(2) : hours.toFixed(1)
		
		}
	
	},
		
		mutations:{
			
			"selected/quantity":function(t,e){
				t.selected.quantity=e
			},
			
			"selected/appliance":function(t,e){
				t.selected.appliance=e
			},
			
			"selected/material":function(t,e){
				t.selected.material=e
			}
		},
		
	actions:{}

})