<template>

<div class="output">
	
	<div class="output-title">to power a</div> 
	
	<com-select class="output-appliance" align="right" value="appliance" type="appliances" /> 
	
	<div class="output-result"><span>for</span> <span class="output-result-total">{{ hours }}</span> <span>hours</span></div> 
	
	<div class="output-image" :style="{backgroundImage: applianceImage}"></div>

</div>

</template>

<script>

import comSelect from './Select'
import store from '../store'

export default {
	components:{"com-select":comSelect},
	
	computed:{
		
		hours:function(){return store.getters.hours},
	
		applianceImage:function(){return"url(img/appliances/"+store.getters.selected.appliance+(window.innerWidth<640?"-small":"")+".png)"}
	
	}
}
</script>

<style scoped>

.output{width:472px;min-width:472px;padding:135px 48px 48px}

@media only screen and (min-width:640px) and (max-width:1199px){.output{width:calc(50% - 71px);min-width:calc(50% - 71px);padding:70px 20px 20px}.inner{width:308px;margin:0 auto}}

@media only screen and (min-width:480px) and (max-width:639px){.output{width:100%;min-width:100%;padding:30px calc((100% - 420px) / 2)}}

@media only screen and (max-width:479px){.output{width:100%;min-width:100%;padding:30px 15px}}.output-title{font-size:40px;font-weight:700;font-family:Lato,sans-serif;color:#363636;margin-bottom:24px}

@media only screen and (min-width:640px) and (max-width:1199px){.output-title{font-size:22px;margin-bottom:10px}}

@media only screen and (max-width:639px){.output-title{font-size:24px;margin-bottom:0}}.output-result{font-size:40px;line-height:60px;font-weight:700;font-family:Lato,sans-serif;color:#363636;margin-bottom:24px}

@media only screen and (min-width:640px) and (max-width:1199px){.output-result{font-size:22px;line-height:32px;margin-bottom:10px}}

@media only screen and (max-width:639px){.output-result{font-size:18px;line-height:24px}}.output-appliance{margin-bottom:16px;z-index:2}

@media only screen and (min-width:640px) and (max-width:1199px){.output-appliance{margin-bottom:10px}}

@media only screen and (max-width:639px){.output-appliance{margin-bottom:10px}}.output-result span{vertical-align:middle}.output-result-total{font-size:60px;z-index:1}

@media only screen and (min-width:640px) and (max-width:1199px){.output-result-total{font-size:32px}}

@media only screen and (max-width:639px){.output-result-total{font-size:32px}}.output-image{width:390px;height:290px;background-position:50% 0;background-repeat:no-repeat}

@media only screen and (min-width:640px) and (max-width:1199px){.output-image{width:200px;height:130px;background-position:50% 100%;background-size:contain}}

@media only screen and (min-width:480px) and (max-width:639px){.output-image{position:absolute;left:calc(50% + 107px);top:30px;width:103px;height:117px;background-position:50% 50%;background-size:contain;z-index:1}}

@media only screen and (max-width:479px){.output-image{position:absolute;right:15px;top:30px;width:103px;height:117px;background-position:50% 50%;background-size:contain;z-index:1}}

</style>
