<template>

<div class="input">

	<div class="input-title">Recycling</div>

	<com-quantity class="input-amount" />

	<com-select class="input-material" align="left" value="material" type="materials" />

	<div class="input-image" :style="{backgroundImage: materialImage}"></div>

</div>

</template>

<script>

import comQuantity from './Quantity'
import comSelect from './Select'

import store from '../store'

export default {

	components: {
		'com-quantity': comQuantity,
		'com-select': comSelect
	},

	computed:{
		
		materials:function(){return store.getters.materials},
		
		materialImage:function(){return"url(img/materials/"+store.getters.selected.material+(window.innerWidth<640?"-small":"")+".png)"}
		
	}

}
</script>

<style scoped>

.input {width:472px;min-width:472px;padding:48px;background-image:url(/img/bin.png);background-repeat:no-repeat;background-position:0 100%;background-size:443px 303px}

@media only screen and (min-width:640px) and (max-width:1199px){
	
	.input {padding:20px;width:calc(50% - 71px);min-width:calc(50% - 71px);background-size:240px auto;background-position:0 calc(100% + 20px)}
	
}

@media only screen and (min-width:480px) and (max-width:639px){.input{padding:30px calc((100% - 420px) / 2);width:100%;min-width:100%;background-image:none}}

@media only screen and (max-width:479px){.input{padding:30px 15px;width:100%;min-width:100%;background-image:none}}.input-title{font-size:40px;font-weight:700;font-family:Lato,sans-serif;color:#363636;margin-bottom:24px}

@media only screen and (min-width:640px) and (max-width:1199px){.input-title{font-size:22px;margin-bottom:10px}}

@media only screen and (max-width:639px){.input-title{font-size:24px;margin-bottom:10px}}.input-material{z-index:2}.input-image{position:absolute;width:390px;height:200px;bottom:168px;left:48px;background-position:50% 0;background-repeat:no-repeat;z-index:1}

@media only screen and (min-width:640px) and (max-width:1199px){.input-image{position:absolute;width:260px;height:100px;bottom:71px;left:50%;margin-left:-130px;background-position:50% 0;background-size:auto 150px}}

@media only screen and (min-width:480px) and (max-width:639px){.input-image{position:absolute;left:calc(50% + 107px);top:30px;bottom:auto;width:103px;height:117px;background-position:50% 50%;background-size:contain}}

@media only screen and (max-width:479px){.input-image{position:absolute;right:15px;top:30px;bottom:auto;left:auto;width:103px;height:117px;background-position:50% 50%;background-size:contain}}

</style>
