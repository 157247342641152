<template>

<div class="assumptions">

	<div class="assumptions-text">saves enough energy</div> 
	<div class="assumptions-icon"></div> 
	<div class="assumptions-link" v-on:click="onExpandClick">assumptions</div>

	<div class="assumptions-overlay" v-if="is.expanded" v-on:click="onOverlayClick">

		<div class="assumptions-overlay-content">

			<table>
				<thead>
					<tr>
						<th>Recycle</th>
						<th>Weight<small>(oz.)</small></th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>aluminum cans</td><td>0.459</td><td>12oz, eg: soda can		</td>
					</tr>
					<tr>
						<td>cereal boxes</td><td>2.4</td><td>12oz, boxboard		</td>
					</tr>
					<tr>
						<td>plastic jugs</td><td>5.28</td><td>1 gallon, HDPE		</td>
					</tr>
					<tr>
						<td>glass bottles</td><td>8.84</td><td>750 ml - eg: wine bottle </td>		
					</tr>
					<tr>
						<td>magazines</td><td>16</td><td>100 page, glossy paper		</td>
					</tr>
					<tr>
						<td>steel/tin cans</td><td>2.08</td><td>15.5 fl. oz  eg: soup can</td>		

					</tr>
				</tbody>
			</table>
			
			<table>
				<thead>
					<tr>
						<th>Appliance</th>
						<th>Wattage</th>
					</tr>	
				</thead>
				<tbody>
					<tr>
						<td>lightbulb (LED)</td><td>14</td>
					</tr>
					<tr>
						<td>hair dryer</td><td>1538</td>
					</tr>
					<tr>
						<td>laptop</td><td>50</td>
					</tr>
					<tr>
						<td>television</td><td>120</td>
					</tr>
					<tr>
						<td>clothes washer</td><td>425</td>
					</tr>

				</tbody>
			</table>
		<div class="assumptions-overlay-close" v-on:click="onOverlayClick"><i class="fa fa-times"></i></div>

			<p>Source: EPA iWARM</p>

		</div>


	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				expanded: false
			}
		}
			
	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = true

		},

		onOverlayClick: function() {

			this.is.expanded = false

		}
	
	}

}
</script>

<style scoped>

.assumptions{width:256px;min-width:256px;background-color:#284c8e;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}

@media only screen and (min-width:640px) and (max-width:1199px){.assumptions{width:142px;min-width:142px}}

@media only screen and (min-width:480px) and (max-width:639px){.assumptions{width:100%;display:block;padding:30px calc((100% - 420px) / 2);background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAMAAABUpcipAAABC1BMVEUAAAD/pxn/qyz/qin/rjf/sDn/rCz/sj//rjf/pyH/rzj/sDv/rC//rS3/sDf/sDf/sT3/rzj/w3T/sUL/rDD/qSX/rC7/rCz/sT//qST/sT3/rTH/rzf/tUr/rC3/qyz/sT7/qij/rCv/uVb/tkr/rjX/uVP/tUv/w3T/u1j/uln/qy//rDL/pyL/rDP/tEf/qij/skL/rDT/qzD/tkz/sUD/qST/sUH/rjj/qCP/sT//uE//ulz/sD7/sDr/rjf/tUv/sDn/rTD/rTb/ul7/ulf/sED/x4D/rjP/tlL/zpL/qCT/qib/rDP/rDT/rTf/qy7/qzH/qiz/qSn/qCf/pyT/pyH/rzz/rjnPmNvmAAAATXRSTlMADfK882QHBPrw5q+SWUouHhoC/efTysW+vbawqaelnXRwa2Q/MjAjIRsT+/j48+rh39/c28vJx7ivqKCenpiMi4iIgHpyVFBPOS0mFmVOy0UAAAGQSURBVDjLjdPXUiMxEIXhZjGOmMXksOS4OeddcoY+amlmbN7/SbDM1JSHKrX4r79SKLVIbeLj2hQ9o7FMXJXibfRYfj1jxTVrkexFWXXJAdn3qOvMGrAzNzH3smKZ2b2KuXZdwAy+jbj1DOzdeMQdC9gnI7obNdYzpH9U9noh5UHy4Y3mdpuJeYTZP81tZ5xn3+5rU9BzuQNvKO7QWRQr1sJupVswuNPwdedROJaZoJusiOWi5Cw49JUEXCSfg9ftAjxU8/fiaKn82TcNuJSFKccDtwoGaxk56bPasoswm637YZ4zuoObbnv3HqyHxi71E/Okp+z+xx35Wi/KNWZKFG65So+NlKMVGWKWgx9xvz4MsUmhWikKxt3z8GzI0L7NCQo2X7yRdV+V39UpHN8v1ihcK8kZkgNSuhTJj5cckdbPDAPnemOqu843RrpNal8cPMOnPd3tyMAlC5OkNwd/DbtKka6ch2acYn1LGehuRd1W30m9TdHeObaVnbi7cDCznbirTafpUpXiTf1t/FfBA3u/tMBY960aAAAAAElFTkSuQmCC);background-size:24px auto;background-position:calc(50% + 150px) calc(50% - 16px);background-repeat:no-repeat}}

@media only screen and (max-width:479px){.assumptions{width:100%;display:block;padding:30px 15px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAMAAABUpcipAAABC1BMVEUAAAD/pxn/qyz/qin/rjf/sDn/rCz/sj//rjf/pyH/rzj/sDv/rC//rS3/sDf/sDf/sT3/rzj/w3T/sUL/rDD/qSX/rC7/rCz/sT//qST/sT3/rTH/rzf/tUr/rC3/qyz/sT7/qij/rCv/uVb/tkr/rjX/uVP/tUv/w3T/u1j/uln/qy//rDL/pyL/rDP/tEf/qij/skL/rDT/qzD/tkz/sUD/qST/sUH/rjj/qCP/sT//uE//ulz/sD7/sDr/rjf/tUv/sDn/rTD/rTb/ul7/ulf/sED/x4D/rjP/tlL/zpL/qCT/qib/rDP/rDT/rTf/qy7/qzH/qiz/qSn/qCf/pyT/pyH/rzz/rjnPmNvmAAAATXRSTlMADfK882QHBPrw5q+SWUouHhoC/efTysW+vbawqaelnXRwa2Q/MjAjIRsT+/j48+rh39/c28vJx7ivqKCenpiMi4iIgHpyVFBPOS0mFmVOy0UAAAGQSURBVDjLjdPXUiMxEIXhZjGOmMXksOS4OeddcoY+amlmbN7/SbDM1JSHKrX4r79SKLVIbeLj2hQ9o7FMXJXibfRYfj1jxTVrkexFWXXJAdn3qOvMGrAzNzH3smKZ2b2KuXZdwAy+jbj1DOzdeMQdC9gnI7obNdYzpH9U9noh5UHy4Y3mdpuJeYTZP81tZ5xn3+5rU9BzuQNvKO7QWRQr1sJupVswuNPwdedROJaZoJusiOWi5Cw49JUEXCSfg9ftAjxU8/fiaKn82TcNuJSFKccDtwoGaxk56bPasoswm637YZ4zuoObbnv3HqyHxi71E/Okp+z+xx35Wi/KNWZKFG65So+NlKMVGWKWgx9xvz4MsUmhWikKxt3z8GzI0L7NCQo2X7yRdV+V39UpHN8v1ihcK8kZkgNSuhTJj5cckdbPDAPnemOqu843RrpNal8cPMOnPd3tyMAlC5OkNwd/DbtKka6ch2acYn1LGehuRd1W30m9TdHeObaVnbi7cDCznbirTafpUpXiTf1t/FfBA3u/tMBY960aAAAAAElFTkSuQmCC);background-size:24px auto;background-position:calc(100% - 64px) calc(50% - 16px);background-repeat:no-repeat}}

.assumptions-text{font-family:Lato,sans-serif;font-size:40px;line-height:50px;font-weight:700;color:#fff;text-align:center;padding:80px 48px 48px}

@media only screen and (min-width:640px) and (max-width:1199px){.assumptions-text{font-size:22px;line-height:26px;padding:44px 32px 32px}}

@media only screen and (max-width:639px){.assumptions-text{font-size:24px;line-height:30px;padding:0;width:120px;text-align:left}}.assumptions-icon{width:100%;-ms-flex-positive:1;flex-grow:1;height:48px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAMAAABUpcipAAABC1BMVEUAAAD/pxn/qyz/qin/rjf/sDn/rCz/sj//rjf/pyH/rzj/sDv/rC//rS3/sDf/sDf/sT3/rzj/w3T/sUL/rDD/qSX/rC7/rCz/sT//qST/sT3/rTH/rzf/tUr/rC3/qyz/sT7/qij/rCv/uVb/tkr/rjX/uVP/tUv/w3T/u1j/uln/qy//rDL/pyL/rDP/tEf/qij/skL/rDT/qzD/tkz/sUD/qST/sUH/rjj/qCP/sT//uE//ulz/sD7/sDr/rjf/tUv/sDn/rTD/rTb/ul7/ulf/sED/x4D/rjP/tlL/zpL/qCT/qib/rDP/rDT/rTf/qy7/qzH/qiz/qSn/qCf/pyT/pyH/rzz/rjnPmNvmAAAATXRSTlMADfK882QHBPrw5q+SWUouHhoC/efTysW+vbawqaelnXRwa2Q/MjAjIRsT+/j48+rh39/c28vJx7ivqKCenpiMi4iIgHpyVFBPOS0mFmVOy0UAAAGQSURBVDjLjdPXUiMxEIXhZjGOmMXksOS4OeddcoY+amlmbN7/SbDM1JSHKrX4r79SKLVIbeLj2hQ9o7FMXJXibfRYfj1jxTVrkexFWXXJAdn3qOvMGrAzNzH3smKZ2b2KuXZdwAy+jbj1DOzdeMQdC9gnI7obNdYzpH9U9noh5UHy4Y3mdpuJeYTZP81tZ5xn3+5rU9BzuQNvKO7QWRQr1sJupVswuNPwdedROJaZoJusiOWi5Cw49JUEXCSfg9ftAjxU8/fiaKn82TcNuJSFKccDtwoGaxk56bPasoswm637YZ4zuoObbnv3HqyHxi71E/Okp+z+xx35Wi/KNWZKFG65So+NlKMVGWKWgx9xvz4MsUmhWikKxt3z8GzI0L7NCQo2X7yRdV+V39UpHN8v1ihcK8kZkgNSuhTJj5cckdbPDAPnemOqu843RrpNal8cPMOnPd3tyMAlC5OkNwd/DbtKka6ch2acYn1LGehuRd1W30m9TdHeObaVnbi7cDCznbirTafpUpXiTf1t/FfBA3u/tMBY960aAAAAAElFTkSuQmCC);background-size:40px 48px;background-position:50% 50%;background-repeat:no-repeat}

@media only screen and (min-width:640px) and (max-width:1199px){.assumptions-icon{background-size:24px auto}}

@media only screen and (max-width:639px){.assumptions-icon{display:none}}.assumptions-link{color:#fff;font-size:16px;font-family:Lato,sans-serif;font-weight:400;text-align:center;text-decoration:underline;padding-bottom:32px}

@media only screen and (min-width:640px) and (max-width:1199px){.assumptions-link{font-size:14px;padding-bottom:16px}}

@media only screen and (min-width:480px) and (max-width:639px){.assumptions-link{position:absolute;left:calc(50% + 90px);bottom:46px;font-size:12px;padding:0;text-align:center;width:120px}}

@media only screen and (max-width:479px){.assumptions-link{position:absolute;right:15px;bottom:46px;font-size:12px;padding:0;text-align:center;width:120px}}

.assumptions-link:hover {
	text-decoration: none;
	cursor: pointer;
}

.assumptions-overlay {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.75);
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.assumptions-overlay-content {
	padding: 60px 30px;
	background-color: #fff;
	border-radius: 5pt;
	max-width: 668px;
	width: calc(100% - 20px);
}


@media only screen and (max-width:768px){
	.assumptions-overlay {
		background-color: #fff;
		justify-content: flex-start;
	}
	.assumptions-overlay-content {
		padding: 50px 10px 10px 10px;
		max-width: 640px;
		width: 100%;
	}
}

.assumptions-overlay-content > * {
	margin-bottom: 30px;
}

.assumptions-overlay-content > *:last-child {
	margin-bottom: 0px;
}

.assumptions-overlay-content table {
	width: 100%;
}

.assumptions-overlay-content th {
	font-weight: 500;
	padding: 2px;
	text-align: left;
	font-size: 14px;
	line-height: 24px;
}

.assumptions-overlay-content th small {
	font-size: 12px;
	margin-left: 4px;
	font-weight: 300;
}

.assumptions-overlay-close {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	line-height: 40px;
	position: absolute;
	right: -48px;
	top: 0px;
	z-index: 2000;
	font-size: 24px;
	background-color: #fff;
	cursor: pointer;
	color: #333;
	text-align: center;
}

@media only screen and (max-width:768px){
.assumptions-overlay-close {
	right: 0px;
}
}

.assumptions-overlay-content td {
	font-size: 14px;
	line-height: 24px;
	padding: 2px;
	color: #333;
}

.assumptions-overlay-content tr:nth-child(odd) td {
	background-color: #efefef;
}

.assumptions-overlay-content th:nth-child(1),
.assumptions-overlay-content td:nth-child(1) {
	width: 200px;
}


@media only screen and (max-width:768px){

.assumptions-overlay-content th:nth-child(1),
.assumptions-overlay-content td:nth-child(1) {
	width: 120px;
}

}

</style>
