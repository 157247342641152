<template>

<div id="app" class="app">

	<com-input />
	<com-assumptions />
	<com-output />

</div>

</template>

<script>

import comOutput from './components/Output.vue'
import comInput from './components/Input.vue'
import comAssumptions from './components/Assumptions.vue'

export default {
  name: 'App',
  components: {
	'com-output': comOutput,
	'com-input': comInput,
	'com-assumptions': comAssumptions
  }
}

</script>

<style>

body{
	height:100%;
	width:100%;
	min-width:100vw;
	min-height:100vh;
	font-weight:300;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	overflow:hidden;
	background-color:#fff;
}

.app,body{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:row;
	flex-direction:row
}

.app{
	width:1200px;
	height:654px;
	border:1px solid #eee
}

@media only screen and (min-width:640px) and (max-width:1199px){
	
	.app{width:640px;height:324px}
	
}

@media only screen and (max-width:639px){
	
	.app{-ms-flex-direction:column;flex-direction:column;width:100%;height:auto}
	
}

</style>
